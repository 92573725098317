import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useWindowSize } from 'hooks/useWindowSize';
import { SIDEBAR_LEFT_SNAP_WIDTH } from 'components/template-designer/constants';
import './main.scss';

interface Props {
    className?: string;
    width: number;
    onResizeStart?: () => void;
    onResize: (width: number) => void;
    onResizeEnd?: (width: number) => void;
    minWidth?: number;
    maxWidth?: number;
    snapTo?: number | (() => number);
    snapThreshold?: number;
}

const MIN_WIDTH = 248;
const MAX_WIDTH = 450;

const VerticalResizeBar = ({
    className,
    width,
    onResizeStart,
    onResizeEnd,
    onResize,
    minWidth = MIN_WIDTH,
    maxWidth = MAX_WIDTH,
    snapTo,
    snapThreshold = SIDEBAR_LEFT_SNAP_WIDTH
}: Props) => {
    const newWidthRef = useRef<number>(width);
    const [resizing, setResizing] = useState<boolean>(false);
    const [snapped, setSnapped] = useState<boolean>(false); // State to track if snapping occurred
    const { width: windowWidth } = useWindowSize();

    // On change of window width and on mount check if the sidebar width is within the min and max width
    useEffect(() => {
        if (width > maxWidth) {
            return onResize(maxWidth);
        }
        if (width < minWidth) {
            return onResize(minWidth);
        }
    }, [windowWidth]);

    /**
     * Handles the resizing of the sidebar.
     * When the new width is less than the minimum width, the width is set to the minimum width.
     * When the new width is greater than the maximum width, the width is set to the maximum width.
     * If the snapTo prop is provided and the new width is within the snap threshold, the width is set to the snapTo value.
     * @param newWidth - The new width of the sidebar.
     */
    const handleResize = (newWidth: number) => {
        if (newWidth < minWidth) newWidth = minWidth;
        if (newWidth > maxWidth) newWidth = maxWidth;

        if (snapTo && !snapped) {
            const snapToWidth = typeof snapTo === 'number' ? snapTo : snapTo();
            if (Math.abs(newWidth - snapToWidth) <= snapThreshold) {
                newWidth = snapToWidth;
                setSnapped(true);
            }
        } else if (snapped && snapTo) {
            const snapToWidth = typeof snapTo === 'number' ? snapTo : snapTo();
            if (Math.abs(newWidth - snapToWidth) > snapThreshold) {
                setSnapped(false);
            }
        }

        onResize(newWidth);
    };

    return (
        <div
            className={classNames(
                'template-designer__vertical-resize-bar',
                {
                    ['template-designer__vertical-resize-bar--min-width']: width === minWidth,
                    ['template-designer__vertical-resize-bar--max-width']: width === maxWidth,
                    ['template-designer__vertical-resize-bar--resizing']: resizing
                },
                className
            )}
            onMouseDown={(event) => {
                event.preventDefault();

                setResizing(true);
                onResizeStart?.();

                const initialX = event.clientX;

                const handleMouseMove = (event: MouseEvent) => {
                    const deltaX = event.clientX - initialX;
                    const newWidth = width + deltaX;
                    newWidthRef.current = newWidth;
                    handleResize(newWidth);
                };

                const handleMouseUp = () => {
                    document.removeEventListener('mousemove', handleMouseMove);
                    document.removeEventListener('mouseup', handleMouseUp);
                    setResizing(false);
                    setSnapped(false);
                    onResizeEnd?.(newWidthRef.current);
                };

                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
            }}>
            <div className="template-designer__vertical-resize-bar__bar" />
        </div>
    );
};

export { VerticalResizeBar };
