import React, { useState, useEffect } from 'react';
import { IconProps } from '@mui/material';
import classNames from 'classnames';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { useEventEmitterListener } from 'hooks/useEventEmitterListener';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Typography from 'components/ui-components-v2/Typography';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import { OverwriteIndicator } from './components/overwrite-indicator';
import './styles/main.scss';

export type ExpandSections =
    | 'transform'
    | 'transition'
    | 'style'
    | 'text'
    | 'background'
    | 'spacing'
    | 'appearance'
    | 'border'
    | 'boxShadow'
    | 'formatStyling'
    | 'formatScript'
    | 'layerSettings'
    | 'boxSettings'
    | 'smartLayout'
    | 'audio'
    | 'text'
    | 'lottie'
    | 'spacing'
    | 'media'
    | 'guides'
    | 'audio'
    | 'customCSS'
    | 'lottie'
    | 'scale'
    | 'dynamicOptions'
    | 'customCode'
    | 'inputSources'
    | 'mediaManipulation'
    | 'outputSettings'
    | 'otherSettings'
    | 'mainSettings'
    | 'helpPanelChecklist'
    | 'helpPanelHelpItems'
    | 'safeZone';

interface Props {
    className?: string;
    expandSectionKey: ExpandSections;
    title?: string;
    tooltip?: string;
    tooltipIconColor?: IconProps['color'];
    tooltipIcon?: string;
    children?: React.ReactNode;
    size?: 'small' | 'medium';
    totalOverwrites?: number;
    condition?: boolean;
    defaultOpen?: boolean;
    open?: boolean;
    setOpen?: (open: boolean) => void;
    disabled?: boolean;
    includeBorderBottom?: boolean;
    dataCyPrefix?: string;
}

const ExpandSection = ({
    className,
    expandSectionKey,
    title,
    tooltip,
    tooltipIconColor = 'warning',
    tooltipIcon = 'warning',
    children,
    size = 'small',
    totalOverwrites,
    condition,
    defaultOpen,
    open,
    setOpen,
    disabled,
    includeBorderBottom = true,
    dataCyPrefix
}: Props) => {
    const expandSectionEvent = useEventEmitterListener(EventEmitterTypes.TDlayerEditExpandSection);

    const [expanded, setExpanded] = useState<boolean>(defaultOpen || false);

    /**
     * Expand or collapse the section based on the accordion event.
     */
    useEffect(() => {
        if (!expandSectionEvent) return;

        if (expandSectionEvent.expandSectionKey === expandSectionKey || expandSectionEvent.expandSectionKey === 'all') {
            if (expandSectionEvent.action === 'open') setExpanded(true);
            if (expandSectionEvent.action === 'close') setExpanded(false);
        }
        if (expandSectionEvent.action === 'oneOpen') {
            if (expandSectionEvent.expandSectionKey === expandSectionKey) setExpanded(true);
            else setExpanded(false);
        }
    }, [expandSectionEvent]);

    /**
     * Set the default open state when it's changed.
     */
    useEffect(() => {
        if (defaultOpen === undefined) return;
        setExpanded(defaultOpen);
    }, [defaultOpen]);

    if (condition !== undefined && !condition) return null;

    return (
        <Accordion
            className={classNames(
                'template-designer__expand-section',
                {
                    ['template-designer__expand-section--border-bottom']: includeBorderBottom,
                    ['template-designer__expand-section--disabled']: disabled,
                    ['template-designer__expand-section--small']: size === 'small',
                    ['template-designer__expand-section--medium']: size === 'medium'
                },
                className
            )}
            expanded={open ?? expanded}
            square
            onChange={() => {
                if (open !== undefined && setOpen !== undefined) return setOpen(!open);
                setExpanded((prevState) => !prevState);
            }}
            TransitionProps={{ unmountOnExit: true }}>
            {(!disabled || title) && (
                <AccordionSummary
                    className="template-designer__expand-section__summary"
                    expandIcon={!disabled ? <Icon>expand_more</Icon> : undefined}
                    data-cy={`${dataCyPrefix}Section-div`}>
                    {title && (
                        <Typography variant="h5" className="template-designer__expand-section__title">
                            {title}
                        </Typography>
                    )}
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <Icon className="template-designer__expand-section__tooltip" color={tooltipIconColor}>
                                {tooltipIcon}
                            </Icon>
                        </Tooltip>
                    )}
                    {!!totalOverwrites && <OverwriteIndicator totalOverwrites={totalOverwrites} />}
                </AccordionSummary>
            )}
            <AccordionDetails className="template-designer__expand-section__details">{children}</AccordionDetails>
        </Accordion>
    );
};

export { ExpandSection };
